export const en = {
    addLanguage: 'Add language',
    editLanguage: 'Edit language',
    language: 'language',
    name: 'Name',
    alaxsName: 'Alaxs Name',
    sort: 'Sort',
    status: 'Status',
    remark: 'Remark',
    created_at: 'Create Time'


}

export const cn = {
    addLanguage: '添加语言',
    editLanguage: '添加语言',
    language: '语言',
    name: '语言名称',
    alaxsName:'语言别名',
    sort: '显示顺序',
    status: '显示状态',
    remark: '语言备注',
    created_at: '添加时间'
}