export const en = {
    addLanguage: 'Add language',
    editLanguage: 'Edit language',
    language: 'language',
    name: 'Name',
    alaxsName: 'Alaxs Name',
    sort: 'Sort',
    status: 'Status',
    remark: 'Remark',
    created_at: 'Create Time'


}

export const cn = {
    name: '会员账号',
    openaccount:'开户名',
    bank_card: '卡号',
    relation_account: '关联账户名',
    open_account: '开户名',
    relation_bank: '关联银行',
    open_address: '开户地址',
    bind_date: '绑定日期',
    status: '状态',
}