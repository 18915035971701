export const en = {
    addNotice: 'Add Notice',
    editNotice: 'Edit Notice',
    notice: 'Notice',
    title: 'Title',
    url: 'Url',
    picture: 'Picture',
    is_publish: 'Publish',
    sort: 'Sort',
    status: 'Status',
    remark: 'Remark',
    created_at: 'Create Time',
    category:'Category',
    noticecategory:'Notice Category',
    language:'Language',
    choosecategory: 'Please Choose Category',
    chooselanguage: 'Please Choose Language',
    category_title: 'Title',
    en_category_title:'En Title',


}

export const cn = {
    addNotice: '添加公告',
    editNotice: '编辑公告',
    notice: '公告',
    title: '公告标题',
    url: '链接地址',
    picture: '缩略图',
    is_publish: '是否发布',
    sort: '显示顺序',
    status: '显示状态',
    remark: '分类备注',
    created_at: '添加时间',
    category:'分类',
    noticecategory:'公告分类',
    language:'公告语言',
    choosecategory: '请选择公告分类',
    chooselanguage: '请选择公告语言',
    category_title: '分类标题',
    en_category_title:'英文标题',
}