export const en = {
    addFlash: 'Add Users',
    editFlash: 'Edit Users',
    nickname: 'Nickname',
    type:'Member Type',
    avatar:'Avatar',
    price: 'Price',
    order: 'Order Num',
    sort: 'Sort',
    status: 'Status',
    created_at: 'Create Time',
}

export const cn = {
    addFlash: '添加用户',
    editFlash: '编辑用户',
    nickname: '用户昵称',
    avatar:'用户头像',
    type:'用户类型',
    order: '订单数量',
    price: '金额',
    sort: '显示顺序',
    status: '显示状态',
    created_at: '添加时间',
}