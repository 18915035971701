<template>
  <el-container>
    <el-form :model="regform" :rules="rules" ref="form">
    <div class="register">
      <div class="tip">登陆信息</div>
      <el-row :gutter="20">
        <el-col :span="12">
          <el-form-item label="中文名称" label-width="100px" prop="realname" required>
            <el-input v-model="regform.realname" placeholder="请输入中文名称" autocomplete="off"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="登陆账号" label-width="100px" prop="account" required>
            <el-input v-model="regform.account" placeholder="请输入登陆账号" autocomplete="off"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="12">
          <el-form-item label="登陆密码" label-width="100px" prop="password" required>
            <el-input type="password" placeholder="请输入登陆密码" v-model="regform.password" autocomplete="off"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="确认密码" label-width="100px" prop="repassword" required>
            <el-input type="password" placeholder="请重复登陆密码" v-model="regform.repassword" autocomplete="off"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <div class="tip">联系信息</div>
      <el-row :gutter="20">
        <el-col :span="12">
          <el-form-item label="联系电话" label-width="100px" prop="mobile" required>
            <el-input placeholder="请输入联系电话" v-model="regform.mobile" autocomplete="off"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="电子邮箱" label-width="100px" prop="email" required>
            <el-input placeholder="请输入电子邮箱" v-model="regform.email" autocomplete="off"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <div class="tip">单位信息</div>
      <el-row :gutter="20">
        <el-col :span="12">
          <el-form-item label="单位代码" label-width="100px" prop="unitCode" required>
            <el-input placeholder="请输入单位代码" v-model="regform.unitCode" autocomplete="off"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="费用代码" label-width="100px" prop="unitCostCode" required>
            <el-input placeholder="请输入费用代码" v-model="regform.unitCostCode" autocomplete="off"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="24">
          <el-form-item label="单位名称" label-width="100px" prop="unitName" required>
            <el-input placeholder="请输入单位名称" v-model="regform.unitName" autocomplete="off"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <div class="tip">用户类型</div>
      <el-row :gutter="20">
        <el-col :span="24">
          <el-form-item prop="roleid">
            <el-checkbox-group v-model="regform.roleid">
              <template v-for="item in userrole">
              <el-checkbox @change="getRoleId" :label="item.role_id" name="roleid" border>{{item.role_name}}</el-checkbox>
              </template>
            </el-checkbox-group>
          </el-form-item>
        </el-col>
      </el-row>
      <div class="tip" v-if="isshowaudio">审核主管<span>（用户类型是客户时需要指定）</span></div>
      <div v-if="isshowaudio">
      <el-row :gutter="20" v-if="isshowaudio">
        <el-col :span="12">
          <el-form-item label="一般件" label-width="100px" prop="auditGeneral">
            <el-select v-model="regform.auditGeneral">
              <el-option value="0">请选择</el-option>
              <template v-for="item in audiopeople">
              <el-option :key="item.id" :label="item.realname" :value="item.id"></el-option>
              </template>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="急件" label-width="100px" prop="auditUrgent">
            <el-select v-model="regform.auditUrgent">
              <el-option value="">请选择</el-option>
              <el-option
                  v-for="item in audiopeople"
                  :key="item.id"
                  :label="item.realname"
                  :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="12">
          <el-form-item label="特急件" label-width="100px" prop="auditExtraUrgent">
            <el-select v-model="regform.auditExtraUrgent">
              <el-option value="">请选择</el-option>
              <el-option
                  v-for="item in audiopeople"
                  :key="item.id"
                  :label="item.realname"
                  :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="即时投入件" label-width="100px" prop="auditImmediately">
            <el-select v-model="regform.auditImmediately">
              <el-option value="">请选择</el-option>
              <el-option
                  v-for="item in audiopeople"
                  :key="item.id"
                  :label="item.realname"
                  :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      </div>
      <div class="tip">其他信息</div>
      <el-row :gutter="20">
        <el-col :span="24">
          <el-form-item label="备注信息" placeholder="请输入备注信息" label-width="100px" prop="remarks">
            <el-input type="textarea" v-model="regform.remarks" autocomplete="off"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-form-item>
        <el-button type="primary" :loading="isbusy" @click="dosubmit('form')">提交注册</el-button>
        <el-button @click="gologin">前往登陆</el-button>
      </el-form-item>
    </div>
    </el-form>
  </el-container>
</template>
<script>
export default {
  name: "register",
  data() {
    return {
      regform:{
        realname:'',
        account:'',
        password:'',
        mobile:'',
        email:'',
        roleid:[],
        unitName:'',
        unitCode:'',
        unitCostCode:'',
        auditGeneral:'',
        auditUrgent:'',
        auditExtraUrgent:'',
        auditImmediately:'',
      },
      isbusy:false,
      audiopeople:[],
      userrole:[],
      isshowaudio:true,
      rules:{
        realname:[
          { required: true, message: '请输入中文名称', trigger: 'blur' },
          { min:2, message: '长度必须大于2个字符', trigger: 'blur' }
        ],
        account:[
          { required: true, message: '请输入登陆账号', trigger: 'blur' },
          { min:6, max:15, message: '账号必须在6-15个字符之间', trigger: 'blur' }
        ],
        password:[
          { required: true, message: '请输入登陆密码', trigger: 'blur' },
          { min:6, max:15, message: '密码必须在6-15个字符之间', trigger: 'blur' }
        ],
        repassword:[
          { required: true, message: '请重复登陆密码', trigger: 'blur' },
          { min:6, max:15, message: '密码必须在6-15个字符之间', trigger: 'blur' }
        ],
        mobile:[
          { required: true, message: '请输入联系电话', trigger: 'blur' },
          { min:1, message: '请输入正确的联系电话', trigger: 'blur' }
        ],
        email:[
          { required: true, message: '请输入电子邮箱', trigger: 'blur' },
          { type:'email', message: '请输入正确的电子邮箱', trigger: 'blur' }
        ],
        unitCode:[
          { required: true, message: '请输入单位代码', trigger: 'blur' }
        ],
        unitCostCode:[
          { required: true, message: '请输入费用代码', trigger: 'blur' }
        ],
        unitName:[
          { required: true, message: '请输入单位名称', trigger: 'blur' }
        ],
        roleid:[
          { required: true, message: '请选择用户类型', trigger: 'blur' }
        ]
      }
    };
  },
  mounted() {
    this.getAudioPeople();
    this.getUserRole();
  },
  methods: {
    getRoleId(){
      if( this.regform.roleid.length == 0 ){
        this.isshowaudio = true;
      } else {
        if( this.regform.roleid.indexOf(3) != -1 ){
          this.isshowaudio = true;
        } else {
          this.isshowaudio = false;
        }
      }
    },
    async getUserRole(){
      let res = await this.httpget('/system/login/getrole');
      if (res.code === 0) {
        this.userrole = res.data;
        this.isbusy = false;
      } else {
        this.isbusy = false;
        this.$message({type: 'error', message: res.msg});
      }
    },
    async getAudioPeople(){
      let res = await this.httpget('/system/login/getaudio', this.noticeformdata);
      if (res.code === 0) {
        this.audiopeople = res.data;
        this.isbusy = false;
      } else {
        this.isbusy = false;
        this.$message({type: 'error', message: res.msg});
      }
    },
    dosubmit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.suresubmit();
        } else {
          return false;
        }
      });
    },
    async suresubmit() {
      let that = this;
      this.isbusy = true;
      if( this.regform.roleid.indexOf(3) != -1 && (!this.regform.auditUrgent || !this.regform.auditImmediately || !this.regform.auditGeneral || !this.regform.auditExtraUrgent)){
        this.isbusy = false;
        this.$message({type:'error',message:'请选择审核主管'});
        return false;
      }
      let res = await this.httppost('/system/login/register', this.regform);
      if (res.code === 0) {
        this.$message({type: 'success', message: res.msg});
        setTimeout(function(){
          that.$router.push({path: '/login'}).catch(err => {err});
        },1500);
        this.isbusy = false;
      } else {
        this.isbusy = false;
        this.$message({type: 'error', message: res.msg});
      }
    },
    gologin(){
      this.$router.push({path: '/login'}).catch(err => {err});
    },
  }
}
</script>
<style lang="scss" scoped>
.el-container {
  display: unset;
  height: 100%;
  background-size: cover;
  -webkit-font-smoothing: antialiased;
}
.register{
  width:1000px;
  padding:10px 50px;
  margin:0px auto;
  background:#ffffff;
  .tip {
    padding: 8px 16px;
    background-color: #ecf8ff;
    border-radius: 0px;
    border-left: 5px solid #50bfff;
    margin: 0px 0px 10px;
    span{
      color:#ff0000;
    }
  }
}
</style>
