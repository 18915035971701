export const en = {

}

export const cn = {
    agent: '代理迁移',
    do_user: '操作员',
    do_content: '操作内容',
    created_at: '操作时间',
    do_agent: '被迁移代理',
    to_agent: '迁移至代理',
    safe_code: '安全码',
}