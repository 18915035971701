<template>
<!--  <el-image :src="welcome" style="width: 100%;"></el-image>-->
  <el-main>
    <div style="padding:10px;">
      <el-row :gutter="20">
        <el-col :span="12">
          <div class="grid-content bg-purple">
            <el-card class="box-card">
              <div slot="header" class="clearfix">
                <span>系统公告</span>
              </div>
              <div class="pagecontent">
                <el-table ref="multipleTable"
                          :data="list"
                          stripe
                          @cell-click="handleShow"
                          tooltip-effect="dark"
                          style="width:100%">
                  <el-table-column
                      label="ID"
                      width="50px"
                      type="index">
                  </el-table-column>
                  <el-table-column
                      prop="title"
                      label="标题">
                  </el-table-column>
                  <el-table-column
                      prop="created_at"
                      width="160"
                      label="发布时间">
                  </el-table-column>
                </el-table>
                <el-pagination
                    background
                    style="margin-top:10px;"
                    @size-change="SizeChange(0)"
                    @current-change="getFirstList(0)"
                    :current-page="searchinfo.page"
                    :page-sizes="PAGE.pageSizes"
                    :page-size="searchinfo.pagesize"
                    :layout="PAGE.layout"
                    :total="totalRows">
                </el-pagination>
              </div>
            </el-card>
          </div>
        </el-col>
        <el-col :span="12">
          <div class="grid-content bg-purple-light">
            <el-card class="box-card">
              <div slot="header" class="clearfix">
                <span>操作指引</span>
              </div>
              <div class="pagecontent">
              <el-table ref="multipleTable"
                        :data="listT"
                        stripe
                        tooltip-effect="dark"
                        style="width: 100%">
                <el-table-column
                    label="ID"
                    width="50px"
                    type="index">
                </el-table-column>
                <el-table-column
                    prop="title"
                    label="标题">
                </el-table-column>
                <el-table-column
                    prop="created_at"
                    width="160"
                    label="发布时间">
                </el-table-column>
              </el-table>
                <el-pagination
                    background
                    style="margin-top:10px"
                    @size-change="SizeChange(1)"
                    @current-change="getFirstList(1)"
                    :current-page="searchinfo.pageT"
                    :page-sizes="PAGE.pageSizes"
                    :page-size="searchinfo.pagesizeT"
                    :layout="PAGE.layout"
                    :total="totalRowsT">
                </el-pagination>
            </div>
            </el-card>
          </div>
        </el-col>
      </el-row>
    </div>
    <div style="padding:0px 10px;">
      <el-row :gutter="20">
        <el-col :span="12">
          <div class="grid-content bg-purple">
            <el-card class="box-card">
              <div slot="header" class="clearfix">
                <span>实验室操作</span>
              </div>
              <div class="pagecontent">
                <el-table ref="multipleTable"
                          :data="listF"
                          stripe
                          tooltip-effect="dark"
                          style="width:100%">
                  <el-table-column
                      label="ID"
                      width="50px"
                      type="index">
                  </el-table-column>
                  <el-table-column
                      prop="title"
                      label="标题">
                  </el-table-column>
                  <el-table-column
                      prop="created_at"
                      width="160"
                      label="发布时间">
                  </el-table-column>
                </el-table>
                <el-pagination
                    background
                    style="margin-top:10px;"
                    @size-change="SizeChange(2)"
                    @current-change="getFirstList(2)"
                    :current-page="searchinfo.pageF"
                    :page-sizes="PAGE.pageSizes"
                    :page-size="searchinfo.pagesizeF"
                    :layout="PAGE.layout"
                    :total="totalRows">
                </el-pagination>
              </div>
            </el-card>
          </div>
        </el-col>
        <el-col :span="12">
          <div class="grid-content bg-purple-light">
            <el-card class="box-card">
              <div slot="header" class="clearfix">
                <span>实验室介绍</span>
              </div>
              <div class="pagecontent">
                <el-table ref="multipleTable"
                          :data="listZ"
                          stripe
                          tooltip-effect="dark"
                          style="width: 100%">
                  <el-table-column
                      label="ID"
                      width="50px"
                      type="index">
                  </el-table-column>
                  <el-table-column
                      prop="title"
                      label="标题">
                  </el-table-column>
                  <el-table-column
                      prop="created_at"
                      width="160"
                      label="发布时间">
                  </el-table-column>
                </el-table>
                <el-pagination
                    background
                    style="margin-top:10px;"
                    @size-change="SizeChange(3)"
                    @current-change="getFirstList(3)"
                    :current-page="searchinfo.pageT"
                    :page-sizes="PAGE.pageSizes"
                    :page-size="searchinfo.pagesizeT"
                    :layout="PAGE.layout"
                    :total="totalRowsT">
                </el-pagination>
              </div>
            </el-card>
          </div>
        </el-col>
      </el-row>
    </div>
    <el-dialog :title="dialogtitle" :visible.sync="isshowdialog"
               :close-on-click-modal="false" custom-class="w70">
      <div style="padding:15px 10px 5px; line-height: 1.8; border-top:1px solid #f1f1f1;" v-html="dialogcontent"></div>
      <div slot="footer" class="dialog-footer" style="text-align: center; margin-top:20px;">
        <el-button @click="isshowdialog = false" :disabled="isbusy" :loading="isbusy">关闭</el-button>
      </div>
    </el-dialog>
  </el-main>
</template>
<script>
  // @ is an alias to /src
  export default {
    name: 'home',
    data() {
      return {
        searchinfo:{
          page: 1,
          pagesize: this.PAGE.pageSize,
          pageT: 1,
          pagesizeT: this.PAGE.pageSize,
          pageF: 1,
          pagesizeF: this.PAGE.pageSize,
          pageZ: 1,
          pagesizeZ: this.PAGE.pageSize,
        },
        dialogtitle:'阅读详情',
        dialogcontent:'',
        isbusy:false,
        isshowdialog:false,
        totalRows: 0,
        totalRowsT: 0,
        totalRowsF: 0,
        totalRowsZ: 0,
        menus: [],
        list:[],
        listT:[],
        listF:[],
        listZ:[],
        //welcome: require('../assets/img/welcome.jpg'),
      }
    },
    methods: {
      handleShow(row,column,event,cell){
        this.isshowdialog = true;
        this.dialogtitle = row.title;
        this.dialogcontent = row.info;
      },
      SizeChange(type) {
        this.searchinfo.pagesize = val;
        this.getFirstList(type);
      },
      async getFirstList (type){
        this.searchinfo.type = type;
        let res = await this.httpget('/system/publicapi/getnoticelist',this.searchinfo);
        if (res.code === 0) {
          if( type == 0 ){
            this.list = res.data.data;
            this.totalRows = res.data.total;
          } else if( type == 1 ){
            this.listT = res.data.data;
            this.totalRowsT = res.data.total;
          } else if( type == 2 ){
            this.listF = res.data.data;
            this.totalRowsF = res.data.total;
          } else if( type == 3 ){
            this.listZ = res.data.data;
            this.totalRowsZ = res.data.total;
          }
        } else {
          console.log('fail');
        }
      }
    },
    computed: {
      userinfo: {
        get: function() {
          return this.$store.getters.getUserInfo;
        }
      },
    },
    mounted() {
      this.getFirstList(0);
      this.getFirstList(1);
      this.getFirstList(2);
      this.getFirstList(3);
    }
  }
</script>
