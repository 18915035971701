import Vue from 'vue'
import VueRouter from 'vue-router'
import index from '@/views/index.vue'
import home from '@/views/home.vue'
import login from '@/views/login.vue'
import register from '@/views/register.vue'

Vue.use(VueRouter)
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push (location) {
  return originalPush.call(this, location).catch(err => err)
}
const routes = [
  {
    path: '/login',
    name: 'login',
    component: login
  },
  {
    path: '/register',
    name: 'register',
    component: register
  },
  {
    path: '/',
    redirect: '/home',
    component: index,
    children: [
      {
        path: '/home',
        name: 'home',
        component: home
      },
      {
        path: '/system/node',
        name: 'node',
        component: () => import(/* webpackChunkName: "node" */ '@/views/system/node.vue')
      },
      {
        path: '/system/user',
        name: 'user',
        component: () => import(/* webpackChunkName: "user" */ '@/views/system/user.vue')
      },
      {
        path: '/system/role',
        name: 'role',
        component: () => import(/* webpackChunkName: "role" */ '@/views/system/role.vue')
      },
      {
        path: '/system/optlog',
        name: 'optlog',
        component: () => import(/* webpackChunkName: "optlog" */ '@/views/system/optlog.vue')
      },
      {
        path: '/system/settings',
        name: 'settings',
        component: () => import(/* webpackChunkName: "systemsettings" */ '@/views/system/setting.vue')
      },
      {
        path: '/base/custom',
        name: 'custom',
        component: () => import(/* webpackChunkName: "systemsettings" */ '@/views/base/custom.vue')
      },
      {
        path: '/base/sample',
        name: 'sample',
        component: () => import(/* webpackChunkName: "systemsettings" */ '@/views/base/sample.vue')
      },
      {
        path: '/base/detect',
        name: 'detect',
        component: () => import(/* webpackChunkName: "systemsettings" */ '@/views/base/detect.vue')
      },
      {
        path: '/base/models',
        name: 'models',
        component: () => import(/* webpackChunkName: "systemsettings" */ '@/views/base/models.vue')
      },
      {
        path: '/base/material',
        name: 'material',
        component: () => import(/* webpackChunkName: "systemsettings" */ '@/views/base/material.vue')
      },
      {
        path: '/base/quality',
        name: 'quality',
        component: () => import(/* webpackChunkName: "systemsettings" */ '@/views/base/quality.vue')
      },
      {
        path: '/base/unit',
        name: 'unit',
        component: () => import(/* webpackChunkName: "systemsettings" */ '@/views/base/unit.vue')
      },
      {
        path: '/base/protect',
        name: 'protect',
        component: () => import(/* webpackChunkName: "systemsettings" */ '@/views/base/protect.vue')
      },
      {
        path: '/base/secret',
        name: 'secret',
        component: () => import(/* webpackChunkName: "systemsettings" */ '@/views/base/secret.vue')
      },
      {
        path: '/maintain/category',
        name: 'category',
        component: () => import(/* webpackChunkName: "systemsettings" */ '@/views/maintain/category.vue')
      },
      {
        path: '/maintain/project',
        name: 'project',
        component: () => import(/* webpackChunkName: "systemsettings" */ '@/views/maintain/project.vue')
      },
      {
        path: '/maintain/device',
        name: 'device',
        component: () => import(/* webpackChunkName: "systemsettings" */ '@/views/maintain/device.vue')
      },
      {
        path: 'message/notice',
        name: 'notice',
        component: () => import(/* webpackChunkName: "systemsettings" */ '@/views/message/notice.vue')
      },
      {
        path: 'message/guide',
        name: 'guide',
        component: () => import(/* webpackChunkName: "systemsettings" */ '@/views/message/guide.vue')
      },
      {
        path: 'message/operate',
        name: 'operate',
        component: () => import(/* webpackChunkName: "systemsettings" */ '@/views/message/operate.vue')
      },
      {
        path: 'message/instroduce',
        name: 'instroduce',
        component: () => import(/* webpackChunkName: "systemsettings" */ '@/views/message/instroduce.vue')
      },
      {
        path: 'entrust/myentrust',
        name: 'myentrust',
        component: () => import(/* webpackChunkName: "systemsettings" */ '@/views/entrust/myentrust.vue')
      },
      {
        path: 'entrust/audioindex',
        name: 'audioindex',
        component: () => import(/* webpackChunkName: "systemsettings" */ '@/views/entrust/audioindex.vue')
      },
      {
        path: 'entrust/dealindex',
        name: 'dealindex',
        component: () => import(/* webpackChunkName: "systemsettings" */ '@/views/entrust/dealindex.vue')
      },
      {
        path: 'entrust/detectindex',
        name: 'detectindex',
        component: () => import(/* webpackChunkName: "systemsettings" */ '@/views/entrust/detectindex.vue')
      },
      {
        path: 'entrust/reportindex',
        name: 'reportindex',
        component: () => import(/* webpackChunkName: "systemsettings" */ '@/views/entrust/reportindex.vue')
      },
      {
        path: 'entrust/sureindex',
        name: 'sureindex',
        component: () => import(/* webpackChunkName: "systemsettings" */ '@/views/entrust/sureindex.vue')
      },
      {
        path: 'entrust/sampleindex',
        name: 'sampleindex',
        component: () => import(/* webpackChunkName: "systemsettings" */ '@/views/entrust/sampleindex.vue')
      },
      {
        path: 'entrust/search',
        name: 'search',
        component: () => import(/* webpackChunkName: "systemsettings" */ '@/views/entrust/search.vue')
      },
      {
        path: 'entrust/entrustindex',
        name: 'entrustindex',
        component: () => import(/* webpackChunkName: "systemsettings" */ '@/views/entrust/entrustindex.vue')
      },
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: '/',
  routes
})

export default router
