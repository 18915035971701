/**
 * 常用的常量定义
 */
export const PAGE = {
    pageSize: 20, // 每页记录数
    pageSizes: [20, 40, 60, 80, 100, 200, 300, 500, 1000], // 每页记录数选项
    layout: 'total, sizes, prev, pager, next, jumper' // 分页类型
};

export const FUND_OPT_TYPE = [
    {value: 0, text: 'All',},
    {value: 1, text: 'recharge'}, // 加款 ： 后台手动审核的时候  线上充值 回调接口  已好了 。请重新测试
    {value: 2, text: 'cash'}, // 减款 :  方案一： 申请加减款流水 ，失败加回款流水 方案二：成功才加流水 暂时用的二,
    {value: 3, text: 'MembershipUpgrade'}, // 减款 // 已好，请测试
    {value: 4, text: 'payforvip'}, // 减款 // 已好，请测试
    {value: 5, text: 'taskamount'}, // 加款 // 已经加  脚本自动审核的未加
    {value: 6, text: "taskincome"}, //加款 // 已经加  脚本自动审核的未加
    {value: 7, text: 'lddeposit'}, // 减款 // 已加  请测试
    {value: 8, text: 'yebtransferin'}, //减款 // 已加 ，请测试
    {value: 9, text: 'yebtransferout'}, //加款 // 已加，请测试
    {value: 10, text: 'taskrewards'}, //加款  签到也算 新手任务 // 已经添加
    {value: 11, text: 'referralincome'}, //加款
    {value: 12, text: 'systemadded'}, //手动加款 管理员加款
    {value: 13, text: 'systemsubed'}, //手动扣款 管理员扣款
];