export const cn = {
    account: '关联账户',
    relation_account: '关联账户名',
    sharelink: '注册链接',
    copy: '复制地址',
    invited_code: '邀请码',
}

export const en = {
    account: '关联账户',
    relation_account: '关联账户名',
    sharelink: '注册链接',
    copy: '复制地址',
    invited_code: '邀请码',
}

export const th = {
    account: '关联账户',
    relation_account: '关联账户名',
    sharelink: '注册链接',
    copy: '复制地址',
    invited_code: '邀请码',
}