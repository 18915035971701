export const en = {
    addIp: 'Add Ip',
    Ip: 'IP',
    remark: 'Remark',
    created_at: 'Create Time'


}

export const cn = {
    addIp: '添加IP',
    Ip: 'IP',
    remark: 'ip备注',
    created_at: '添加时间'
}